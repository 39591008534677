@media only screen and (min-width: 768px) {
  .body-chat {
    height: 35rem;
    width: 60rem;
    background-color: white !important;
    overflow: hidden;
    z-index: 999;
    transition: all 100ms linear;
  }
}

.indicator-chat::before {
  content: "";
  background-color: lime !important;
  height: 10px;
  width: 10px;
  border-radius: 100px;
  margin-right: 8px;
  display: inline-block;
  justify-content: center;
  align-items: center;
}

.profile-chat-pv {
  margin-right: 16px;
  background-color: gray;
  height: 52px;
  width: 52px;
}

.profile-chat-list {
  margin-right: 16px;
  background-color: gray;
  height: 42px;
  width: 42px;
}

.profile-chat-msg {
  background-color: gray;
  height: 42px;
  width: 42px;
}

.search-chat {
  background-color: #f3f3f3 !important;
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}

.list-container {
  position: relative;
  height: inherit;
}

.list-item {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  padding: 16px 0;
  overflow-y: scroll;
}

.item {
  overflow: hidden;
}

.time-chat {
  margin-left: 4px;
  font-size: 10px;
  font-weight: 400;
}

.pointer {
  cursor: pointer;
}

.msg-chat-list {
  margin-top: 4px;
  color: gray;
  font-size: 13px;
  font-weight: 400;
}

.ellipsis {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  /* start showing ellipsis when 3rd line is reached */
  white-space: pre-wrap;
  /* let the text wrap preserving spaces */
}

.multiline-ellipsis {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  /* start showing ellipsis when 3rd line is reached */
  white-space: pre-wrap;
  /* let the text wrap preserving spaces */
}

.singleline-ellipsis {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  white-space: pre-wrap;
}

.ul-message {
  list-style-type: none;
}

.chat-message {
  padding: 10px 16px;
  border-radius: 10px !important;
  min-width: 6em;
  max-width: 25em;
  margin: 0 16px;
  text-wrap: wrap;
  overflow: hidden;
  line-height: 125%;
}

.footer-chat {
  box-shadow: 5px -12px 90px -23px rgba(0, 0, 0, 0.37);
  -webkit-box-shadow: 5px -12px 90px -23px rgba(0, 0, 0, 0.37);
  -moz-box-shadow: 5px -12px 90px -23px rgba(0, 0, 0, 0.37);
}

.header-chat-contact {
  box-shadow: 1px 23px 29px -30px rgba(0, 0, 0, 0.26);
  -webkit-box-shadow: 1px 23px 29px -30px rgba(0, 0, 0, 0.26);
  -moz-box-shadow: 1px 23px 29px -30px rgba(0, 0, 0, 0.26);
}

.btn-chat-float {
  border: 2px solid #f5f5f5 !important;
}
.list-contact {
  position: relative;
}

.dot-new-msg {
  height: 20px;
  width: 20px;
  background-color: #db0f50;
  border-radius: 20px;
  z-index: 9;
  padding-top: 1.5px;
  border: 1px solid #f5f5f5;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 3px;
  margin-left: 8px;
  box-shadow: 0px 6px 14px 1px rgba(0, 0, 0, 0.28);
  -webkit-box-shadow: 0px 6px 14px 1px rgba(0, 0, 0, 0.28);
  -moz-box-shadow: 0px 6px 14px 1px rgba(0, 0, 0, 0.28);
  transition: all 200ms ease-in-out;
}

.dot-new-msg > span {
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  color: smoke;
}
