.banner-pink {
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 6px;
  width: 26rem;
}

.swiper-pagination {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;

  .swiper-pagination-bullet {
    opacity: 1;
    height: 12px !important;
    width: 12px !important;
    border-radius: 100px;
    background-color: rgba($color: #92388e, $alpha: 0.5);
    transition: all 0.3s linear;
  }

  .active {
    background-color: #92388e !important;
  }
}

.carousel-btn-control {
  border: none;
  -moz-border: none;
  // margin: 0 4px;
  background-color: transparent;
  color: #92388e;
  font-size: 24px;
}

.text-10 {
  font-size: 10px;
}

.text-12 {
  font-size: 12px;
}

.text-small {
  font-size: 14px;
}

.text-md {
  font-size: 16px;
}

.text-18 {
  font-size: 18px;
}

.text-20 {
  font-size: 20px;
}

.text-24 {
  font-size: 24px;
}

.text-26 {
  font-size: 26px;
}

.text-xl {
  font-size: 56px;
}

.text-lg {
  font-size: 28px;
  line-height: normal;
}

.text-32 {
  font-size: 32px;
}

.text-42 {
  font-size: 42px;
}

.font-bold {
  font-weight: 700;
}

.text-purple-200 {
  color: #4f44b4;
}

.text-gray-400 {
  color: #5a5a5a;
}

.text-pink-500 {
  color: #92388e;
}

.bg-pink-500 {
  background: #92388e;
}

.bg-gray-200 {
  background-color: #f6f8fa;
}

.bg-gray-400 {
  background-color: #e9ecef;
}

.bg-white {
  background-color: #fff;
}

.bg-purple-500 {
  background-color: #603e92;
}

.bg-orange {
  background-color: #f2994a;
}

.rounded-lg {
  border-radius: 16px;
}

.p-6px {
  padding: 6px;
}

.p-24 {
  padding: 24px;
}

.px-2 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.px-4 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.register-btn {
  background: #fff;
  border-radius: 20px;
  padding: 10px;
  width: 18rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  transition: transform 0.3s ease-in-out;
}

.download-document-btn {
  background: #f2994a;
  border-radius: 20px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  transition: transform 0.3s ease-in-out;
}

.grid-cols-2 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.grid {
  display: grid;
}

.register-btn:hover {
  background: linear-gradient(to left, #92388e, #4f44b4);
  color: white;
  transform: scale(1.1);
}

.download-document-btn:hover {
  color: white;
  transform: scale(1.1);
}

.stats-v2 {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.w-13 {
  width: 33%;
}

.w-18 {
  width: 20% !important;
}

.w-25 {
  width: 25%;
}

.w-39 {
  width: 39%;
}

.w-45 {
  width: 45%;
}

.w-58 {
  width: 58%;
}

.w-65 {
  width: 65%;
}

.w-73 {
  width: 73%;
}

.w-70 {
  width: 68%;
}

.w-75 {
  width: 75%;
}

.w-80 {
  width: 80%;
}

.w-85 {
  width: 85%;
}

.w-90 {
  width: 90%;
}

.w-50 {
  width: 50%;
}

.stream-list {
  display: flex;
  flex-direction: column;
  gap: 4;
  margin-top: 8px;
}

//width
.w-4 {
  width: 1rem;
}

/* 16px */
.w-5 {
  width: 1.25rem;
}

/* 20px */
.w-6 {
  width: 1.5rem;
}

.w-7 {
  width: 1.75rem;
}

/* 28px */
.w-8 {
  width: 2rem;
}

/* 32px */
.w-9 {
  width: 2.25rem;
}

/* 36px */
.w-10 {
  width: 2.5rem;
}

/* 40px */
.w-11 {
  width: 2.75rem;
}

/* 44px */
.w-12 {
  width: 3rem;
}

/* 48px */
.w-14 {
  width: 3.5rem;
}

/* 56px */
.w-16 {
  width: 4rem;
}

/* 64px */
.w-20 {
  width: 5rem;
}

/* 80px */
.w-24 {
  width: 6rem;
}

/* 96px */
.w-28 {
  width: 7rem;
}

/* 112px */
.w-32 {
  width: 8rem;
}

/* 128px */
.w-36 {
  width: 9rem;
}

/* 144px */
.w-40 {
  width: 10rem;
}

/* 160px */
.w-44 {
  width: 11rem;
}

/* 176px */
.w-48 {
  width: 12rem;
}

/* 192px */
.w-52 {
  width: 13rem;
}

/* 208px */
.w-56 {
  width: 14rem;
}

/* 224px */
.w-60 {
  width: 15rem;
}

/* 240px */
.w-64 {
  width: 16rem;
}

/* 256px */
.w-72 {
  width: 18rem;
}

/* 288px */
.w-80 {
  width: 20rem;
}

/* 320px */
.w-96 {
  width: 24rem;
}

.w-full {
  width: 100%;
}

/* 384px */
.w-auto {
  width: auto;
}

.h-2 {
  height: 0.5rem;
}

/* 8px */
.h-2-5 {
  height: 0.625rem;
}

/* 10px */
.h-3 {
  height: 0.75rem;
}

/* 12px */
.h-3-5 {
  height: 0.875rem;
}

/* 14px */
.h-4 {
  height: 1rem;
}

/* 16px */
.h-5 {
  height: 1.25rem;
}

/* 20px */
.h-6 {
  height: 1.5rem;
}

/* 24px */
.h-7 {
  height: 1.75rem;
}

/* 28px */
.h-8 {
  height: 2rem;
}

/* 32px */
.h-9 {
  height: 2.25rem;
}

/* 36px */
.h-10 {
  height: 2.5rem;
}

/* 40px */
.h-11 {
  height: 2.75rem;
}

/* 44px */
.h-12 {
  height: 3rem;
}

/* 48px */
.h-14 {
  height: 3.5rem;
}

/* 56px */
.h-16 {
  height: 4rem;
}

/* 64px */
.h-20 {
  height: 5rem;
}

/* 80px */
.h-24 {
  height: 6rem;
}

/* 96px */
.h-28 {
  height: 7rem;
}

/* 112px */
.h-32 {
  height: 8rem;
}

/* 128px */
.h-36 {
  height: 9rem;
}

/* 144px */
.h-40 {
  height: 10rem;
}

/* 160px */
.h-44 {
  height: 11rem;
}

/* 176px */
.h-48 {
  height: 12rem;
}

/* 192px */
.h-52 {
  height: 13rem;
}

/* 208px */
.h-56 {
  height: 14rem;
}

/* 224px */
.h-60 {
  height: 15rem;
}

/* 240px */
.h-64 {
  height: 16rem;
}

/* 256px */
.h-72 {
  height: 18rem;
}

/* 288px */
.h-80 {
  height: 20rem;
}

/* 320px */
.h-96 {
  height: 24rem;
}

/* 384px */
.h-auto {
  height: auto;
}

.h-full {
  height: 100%;
}

.h-screen {
  height: 100vh;
}

.h-svh {
  height: 100svh;
}

.h-lvh {
  height: 100lvh;
}

.h-dvh {
  height: 100dvh;
}

.h-min {
  height: min-content;
}

.text-black {
  color: rgb(0 0 0);
}

.text-red {
  color: red;
}

.text-center {
  text-align: center;
}

//text-size

.text-xs {
  font-size: 0.75rem;
  /* 12px */
  line-height: 1rem;
}

/* 16px */
.text-sm {
  font-size: 0.875rem;
  /* 14px */
  line-height: 1.25rem;
}

/* 20px */
.text-base {
  font-size: 1rem;
  /* 16px */
  line-height: 1.5rem;
}

// /* 24px */
// .text-lg {
//     font-size: 1.125rem;
//     /* 18px */
//     line-height: 1.75rem;
// }

/* 28px */
// .text-xl {
//     font-size: 1.25rem;
//     /* 20px */
//     line-height: 1.75rem;
// }

/* 28px */
.text-2xl {
  font-size: 1.5rem;
  /* 24px */
  line-height: 2rem;
}

/* 32px */
.text-3xl {
  font-size: 1.875rem;
  /* 30px */
  line-height: 2.25rem;
}

/* 36px */
.text-4xl {
  font-size: 2.25rem;
  /* 36px */
  line-height: 2.5rem;
}

/* 40px */
.text-5xl {
  font-size: 3rem;
  /* 48px */
  line-height: 1;
}

.text-6xl {
  font-size: 3.75rem;
  /* 60px */
  line-height: 1;
}

.text-7xl {
  font-size: 4.5rem;
  /* 72px */
  line-height: 1;
}

.text-8xl {
  font-size: 6rem;
  /* 96px */
  line-height: 1;
}

.text-9xl {
  font-size: 8rem;
  /* 128px */
  line-height: 1;
}

//font-weight
.font-thin {
  font-weight: 100;
}

.font-extralight {
  font-weight: 200;
}

.font-light {
  font-weight: 300;
}

.font-normal {
  font-weight: 400;
}

.font-medium {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

.font-extrabold {
  font-weight: 800;
}

.font-black {
  font-weight: 900;
}

.absolute {
  position: absolute;
}

.right-0 {
  right: 0;
}

.right-1 {
  right: 1rem;
}

.bottom-0 {
  bottom: 0;
}

.bottom-1 {
  bottom: 1rem;
}

.right-10 {
  right: 4rem;
}

.right-12 {
  right: 2.4rem;
}

.right-20 {
  right: 8rem;
}

.relative {
  position: relative;
}

//flex direction
.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-col {
  flex-direction: column;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

//align-items
.align-center {
  text-align: center;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.items-stretch {
  align-items: stretch;
}

//justify-content

.justify-normal {
  justify-content: normal;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly;
}

.justify-stretch {
  justify-content: stretch;
}

//gap
.gap-2 {
  gap: 0.5rem;
  /* 8px */
}

.gap-x-2 {
  column-gap: 0.5rem;
  /* 8px */
}

.gap-y-2 {
  row-gap: 0.5rem;
  /* 8px */
}

.gap-y-4 {
  row-gap: 1rem;
}

.gap-25 {
  gap: 0.625rem;
  /* 10px */
}

.gap-x-25 {
  column-gap: 0.625rem;
  /* 10px */
}

.gap-y-25 {
  row-gap: 0.625rem;
  /* 10px */
}

.gap-3 {
  gap: 0.75rem;
  /* 12px */
}

.gap-x-3 {
  column-gap: 0.75rem;
  /* 12px */
}

.gap-y-3 {
  row-gap: 0.75rem;
  /* 12px */
}

.gap-4 {
  gap: 1rem;
  /* 16px */
}

.gap-x-4 {
  column-gap: 1rem;
  /* 16px */
}

.gap-y-4 {
  row-gap: 1rem;
  /* 16px */
}

.gap-5 {
  gap: 1.25rem;
}

/* 20px */
.gap-x-5 {
  column-gap: 1.25rem;
}

/* 20px */
.gap-y-5 {
  row-gap: 1.25rem;
}

/* 20px */
.gap-6 {
  gap: 1.5rem;
}

/* 24px */
.gap-x-6 {
  column-gap: 1.5rem;
}

/* 24px */
.gap-y-6 {
  row-gap: 1.5rem;
}

/* 24px */
.gap-7 {
  gap: 1.75rem;
}

/* 28px */
.gap-x-7 {
  column-gap: 1.75rem;
}

/* 28px */
.gap-y-7 {
  row-gap: 1.75rem;
}

/* 28px */
.gap-8 {
  gap: 2rem;
}

/* 32px */
.gap-x-8 {
  column-gap: 2rem;
}

/* 32px */
.gap-y-8 {
  row-gap: 2rem;
}

/* 32px */
.gap-9 {
  gap: 2.25rem;
}

/* 36px */
.gap-x-9 {
  column-gap: 2.25rem;
}

/* 36px */
.gap-y-9 {
  row-gap: 2.25rem;
}

/* 36px */
.gap-10 {
  gap: 2.5rem;
}

/* 40px */
.gap-x-10 {
  column-gap: 2.5rem;
}

/* 40px */
.gap-y-10 {
  row-gap: 2.5rem;
}

/* 40px */
.gap-11 {
  gap: 2.75rem;
}

/* 44px */
.gap-x-11 {
  column-gap: 2.75rem;
}

/* 44px */
.gap-y-11 {
  row-gap: 2.75rem;
}

/* 44px */
.gap-12 {
  gap: 3rem;
}

/* 48px */
.gap-x-12 {
  column-gap: 3rem;
}

/* 48px */
.gap-y-12 {
  row-gap: 3rem;
}

/* 48px */

//border

.border-2 {
  border-width: 2px;
}

.border-4 {
  border-width: 4px;
}

.border-8 {
  border-width: 8px;
}

.border-t-2 {
  border-top-width: 2px;
}

.border-t-4 {
  border-top-width: 4px;
}

.border-t-8 {
  border-top-width: 8px;
}

.border-t {
  border-top-width: 1px;
}

.border-r-0 {
  border-right-width: 0px;
}

.border-r-2 {
  border-right-width: 2px;
}

.border-r-4 {
  border-right-width: 4px;
}

.border-r-8 {
  border-right-width: 8px;
}

.border-r {
  border-right-width: 1px;
}

.border-b-0 {
  border-bottom-width: 0px;
}

.border-b-2 {
  border-bottom-width: 2px;
}

.border-b-4 {
  border-bottom-width: 4px;
}

.border-b-8 {
  border-bottom-width: 8px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-l-0 {
  border-left-width: 0px;
}

.border-l-2 {
  border-left-width: 2px;
}

.border-l-4 {
  border-left-width: 4px;
}

.border-l-8 {
  border-left-width: 8px;
}

.border-l {
  border-left-width: 1px;
}

.border-b-gray {
  border-bottom: 1px solid #e9ecef;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

//border radius
.rounded-none {
  border-radius: 0px;
}

.rounded-sm {
  border-radius: 0.125rem;
}

/* 2px */
.rounded {
  border-radius: 0.25rem;
}

/* 4px */
.rounded-md {
  border-radius: 0.375rem;
}

.rounded-xxl {
  border-radius: 20px;
}

/* 6px */
.rounded-lg {
  border-radius: 0.5rem;
}

/* 8px */
.rounded-xl {
  border-radius: 0.75rem;
}

/* 12px */
.rounded-2xl {
  border-radius: 1rem;
}

/* 16px */
.rounded-3xl {
  border-radius: 1.5rem;
}

/* 24px */
.rounded-full {
  border-radius: 9999px;
}

.cursor-pointer {
  cursor: pointer;
}

//margin
.m-0 {
  margin: 0px;
}

.mx-0 {
  margin-left: 0px;
  margin-right: 0px;
}

.my-0 {
  margin-top: 0px;
  margin-bottom: 0px;
}

.ms-0 {
  margin-inline-start: 0px;
}

.me-0 {
  margin-inline-end: 0px;
}

.mt-0 {
  margin-top: 0px;
}

.pt-10 {
  padding-top: 2.5rem;
}

.pt-20 {
  padding-top: 5rem;
}

.mr-0 {
  margin-right: 0px;
}

.mb-0 {
  margin-bottom: 0px;
}

.ml-0 {
  margin-left: 0px;
}

.m-px {
  margin: 1px;
}

.mx-px {
  margin-left: 1px;
  margin-right: 1px;
}

.my-px {
  margin-top: 1px;
  margin-bottom: 1px;
}

.ms-px {
  margin-inline-start: 1px;
}

.me-px {
  margin-inline-end: 1px;
}

.mt-px {
  margin-top: 1px;
}

.mr-px {
  margin-right: 1px;
}

.mb-px {
  margin-bottom: 1px;
}

.ml-px {
  margin-left: 1px;
}

/* 2px */
.m-1 {
  margin: 0.25rem;
}

/* 4px */
.mx-1 {
  margin-left: 0.25rem;

  /* 4px */

  margin-right: 0.25rem;
}

/* 4px */
.my-1 {
  margin-top: 0.25rem;

  /* 4px */

  margin-bottom: 0.25rem;
}

/* 4px */
.ms-1 {
  margin-inline-start: 0.25rem;
}

/* 4px */
.me-1 {
  margin-inline-end: 0.25rem;
}

.mt-05 {
  margin-top: 0.125rem;
}

/* 4px */
.mt-1 {
  margin-top: 0.25rem;
}

/* 4px */
.mr-1 {
  margin-right: 0.25rem;
}

/* 4px */
.mb-1 {
  margin-bottom: 0.25rem;
}

/* 4px */
.ml-1 {
  margin-left: 0.25rem;
}

/* 4px */

/* 6px */
.m-2 {
  margin: 0.5rem;
}

/* 8px */
.mx-2 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

/* 8px */
.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

/* 8px */
.ms-2 {
  margin-inline-start: 0.5rem;
}

/* 8px */
.me-2 {
  margin-inline-end: 0.5rem;
}

/* 8px */
.mt-2 {
  margin-top: 0.5rem;
}

/* 8px */
.mr-2 {
  margin-right: 0.5rem;
}

/* 8px */
.mb-2 {
  margin-bottom: 0.5rem;
}

/* 8px */
.ml-2 {
  margin-left: 0.5rem;
}

/* 8px */

/* 10px */
.m-3 {
  margin: 0.75rem;
}

/* 12px */
.mx-3 {
  margin-left: 0.75rem;
  margin-right: 0.75rem;
}

/* 12px */
.my-3 {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}

/* 12px */
.ms-3 {
  margin-inline-start: 0.75rem;
}

/* 12px */
.me-3 {
  margin-inline-end: 0.75rem;
}

/* 12px */
.mt-3 {
  margin-top: 0.75rem;
}

/* 12px */
.mr-3 {
  margin-right: 0.75rem;
}

/* 12px */
.mb-3 {
  margin-bottom: 0.75rem;
}

/* 12px */
.ml-3 {
  margin-left: 0.75rem;
}

/* 12px */
.m-4 {
  margin: 1rem;
}

/* 16px */
.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

/* 16px */
.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

/* 16px */
.ms-4 {
  margin-inline-start: 1rem;
}

/* 16px */
.me-4 {
  margin-inline-end: 1rem;
}

/* 16px */
.mt-4 {
  margin-top: 1rem;
}

/* 16px */
.mr-4 {
  margin-right: 1rem;
}

/* 16px */
.mb-4 {
  margin-bottom: 1rem;
}

/* 16px */
.ml-4 {
  margin-left: 1rem;
}

/* 16px */
.m-5 {
  margin: 1.25rem;
}

/* 20px */
.mx-5 {
  margin-left: 1.25rem;

  margin-right: 1.25rem;
}

/* 20px */
.my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

/* 20px */
.ms-5 {
  margin-inline-start: 1.25rem;
}

/* 20px */
.me-5 {
  margin-inline-end: 1.25rem;
}

/* 20px */
.mt-5 {
  margin-top: 1.25rem;
}

/* 20px */
.mr-5 {
  margin-right: 1.25rem;
}

/* 20px */
.mb-5 {
  margin-bottom: 1.25rem;
}

/* 20px */
.ml-5 {
  margin-left: 1.25rem;
}

/* 20px */
.m-6 {
  margin: 1.5rem;
}

/* 24px */
.mx-6 {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

/* 24px */
.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

/* 24px */
.ms-6 {
  margin-inline-start: 1.5rem;
}

/* 24px */
.me-6 {
  margin-inline-end: 1.5rem;
}

/* 24px */
.mt-6 {
  margin-top: 1.5rem;
}

/* 24px */
.mr-6 {
  margin-right: 1.5rem;
}

/* 24px */
.mb-6 {
  margin-bottom: 1.5rem;
}

/* 24px */
.ml-6 {
  margin-left: 1.5rem;
}

/* 24px */
.m-7 {
  margin: 1.75rem;
}

/* 28px */
.mx-7 {
  margin-left: 1.75rem;
  margin-right: 1.75rem;
}

/* 28px */
.my-7 {
  margin-top: 1.75rem;
  margin-bottom: 1.75rem;
}

/* 28px */
.ms-7 {
  margin-inline-start: 1.75rem;
}

/* 28px */
.me-7 {
  margin-inline-end: 1.75rem;
}

/* 28px */
.mt-7 {
  margin-top: 1.75rem;
}

/* 28px */
.mr-7 {
  margin-right: 1.75rem;
}

/* 28px */
.mb-7 {
  margin-bottom: 1.75rem;
}

/* 28px */
.ml-7 {
  margin-left: 1.75rem;
}

/* 28px */
.m-8 {
  margin: 2rem;
}

/* 32px */
.mx-8 {
  margin-left: 2rem;
  margin-right: 2rem;
}

/* 32px */
.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

/* 32px */
.ms-8 {
  margin-inline-start: 2rem;
}

/* 32px */
.me-8 {
  margin-inline-end: 2rem;
}

/* 32px */
.mt-8 {
  margin-top: 2rem;
}

/* 32px */
.mr-8 {
  margin-right: 2rem;
}

/* 32px */
.mb-8 {
  margin-bottom: 2rem;
}

/* 32px */
.ml-8 {
  margin-left: 2rem;
}

/* 32px */
.m-9 {
  margin: 2.25rem;
}

/* 36px */
.mx-9 {
  margin-left: 2.25rem;
  margin-right: 2.25rem;
}

/* 36px */
.my-9 {
  margin-top: 2.25rem;
  margin-bottom: 2.25rem;
}

/* 36px */
.ms-9 {
  margin-inline-start: 2.25rem;
}

/* 36px */
.me-9 {
  margin-inline-end: 2.25rem;
}

/* 36px */
.mt-9 {
  margin-top: 2.25rem;
}

/* 36px */
.mr-9 {
  margin-right: 2.25rem;
}

/* 36px */
.mb-9 {
  margin-bottom: 2.25rem;
}

/* 36px */
.ml-9 {
  margin-left: 2.25rem;
}

/* 36px */
.m-10 {
  margin: 2.5rem;
}

/* 40px */
.mx-10 {
  margin-left: 2.5rem;
  margin-right: 2.5rem;
}

/* 40px */
.my-10 {
  margin-top: 2.5rem;
  /* 40px */
  margin-bottom: 2.5rem;
}

/* 40px */
.ms-10 {
  margin-inline-start: 2.5rem;
}

/* 40px */
.me-10 {
  margin-inline-end: 2.5rem;
}

/* 40px */
.mt-10 {
  margin-top: 2.5rem;
}

.mt-12 {
  margin-top: 6.5rem;
}

/* 40px */
.mr-10 {
  margin-right: 2.5rem;
}

/* 40px */
.mb-10 {
  margin-bottom: 2.5rem;
}

/* 40px */
.ml-10 {
  margin-left: 2.5rem;
}

/* 40px */

/* 208px */
.mt-52 {
  margin-top: 13rem;
}

/* 208px */
.mr-52 {
  margin-right: 13rem;
}

/* 208px */
.mb-52 {
  margin-bottom: 13rem;
}

/* 208px */
.ml-52 {
  margin-left: 13rem;
}

/* 208px */
.m-56 {
  margin: 14rem;
}

/* 224px */
.mx-56 {
  margin-left: 14rem;

  /* 224px */
  margin-right: 14rem;
}

/* 224px */
.my-56 {
  margin-top: 14rem;

  /* 224px */
  margin-bottom: 14rem;
}

/* 224px */
.ms-56 {
  margin-inline-start: 14rem;
}

/* 224px */
.me-56 {
  margin-inline-end: 14rem;
}

/* 224px */
.mt-56 {
  margin-top: 14rem;
}

/* 224px */
.mr-56 {
  margin-right: 14rem;
}

/* 224px */
.mb-56 {
  margin-bottom: 14rem;
}

/* 224px */
.ml-56 {
  margin-left: 14rem;
}

/* 224px */
.m-60 {
  margin: 15rem;
}

/* 240px */
.mx-60 {
  margin-left: 15rem;

  /* 240px */
  margin-right: 15rem;
}

/* 240px */
.my-60 {
  margin-top: 15rem;

  /* 240px */
  margin-bottom: 15rem;
}

/* 240px */
.ms-60 {
  margin-inline-start: 15rem;
}

/* 240px */
.me-60 {
  margin-inline-end: 15rem;
}

/* 240px */
.mt-60 {
  margin-top: 15rem;
}

/* 240px */
.mr-60 {
  margin-right: 15rem;
}

/* 240px */
.mb-60 {
  margin-bottom: 15rem;
}

/* 240px */
.ml-60 {
  margin-left: 15rem;
}

/* 240px */
.m-64 {
  margin: 16rem;
}

/* 256px */
.mx-64 {
  margin-left: 16rem;
  margin-right: 16rem;
}

/* 256px */
.my-64 {
  margin-top: 16rem;
  /* 256px */
  margin-bottom: 16rem;
}

/* 256px */
.ms-64 {
  margin-inline-start: 16rem;
}

/* 256px */
.me-64 {
  margin-inline-end: 16rem;
}

/* 256px */
.mt-64 {
  margin-top: 16rem;
}

/* 256px */
.mr-64 {
  margin-right: 16rem;
}

/* 256px */
.mb-64 {
  margin-bottom: 16rem;
}

/* 256px */
.ml-64 {
  margin-left: 16rem;
}

/* 256px */
.m-72 {
  margin: 18rem;
}

/* 288px */
.mx-72 {
  margin-left: 18rem;

  /* 288px */
  margin-right: 18rem;
}

/* 288px */
.my-72 {
  margin-top: 18rem;

  /* 288px */
  margin-bottom: 18rem;
}

/* 288px */
.ms-72 {
  margin-inline-start: 18rem;
}

/* 288px */
.me-72 {
  margin-inline-end: 18rem;
}

/* 288px */
.mt-72 {
  margin-top: 18rem;
}

/* 288px */
.mr-72 {
  margin-right: 18rem;
}

/* 288px */
.mb-72 {
  margin-bottom: 18rem;
}

/* 288px */
.ml-72 {
  margin-left: 18rem;
}

/* 288px */
.m-80 {
  margin: 20rem;
}

/* 320px */
.mx-80 {
  margin-left: 20rem;

  /* 320px */
  margin-right: 20rem;
}

/* 320px */
.my-80 {
  margin-top: 20rem;

  /* 320px */
  margin-bottom: 20rem;
}

/* 320px */
.ms-80 {
  margin-inline-start: 20rem;
}

/* 320px */
.me-80 {
  margin-inline-end: 20rem;
}

/* 320px */
.mt-80 {
  margin-top: 20rem;
}

/* 320px */
.mr-80 {
  margin-right: 20rem;
}

/* 320px */
.mb-80 {
  margin-bottom: 20rem;
}

/* 320px */
.ml-80 {
  margin-left: 20rem;
}

/* 320px */
.m-96 {
  margin: 24rem;
}

/* 384px */
.mx-96 {
  margin-left: 24rem;
  margin-right: 24rem;
}

/* 384px */
.my-96 {
  margin-top: 24rem;
  margin-bottom: 24rem;
}

/* 384px */
.ms-96 {
  margin-inline-start: 24rem;
}

/* 384px */
.me-96 {
  margin-inline-end: 24rem;
}

/* 384px */
.mt-96 {
  margin-top: 24rem;
}

/* 384px */
.mr-96 {
  margin-right: 24rem;
}

/* 384px */
.mb-96 {
  margin-bottom: 24rem;
}

/* 384px */
.ml-96 {
  margin-left: 24rem;
}

/* 384px */
.m-auto {
  margin: auto;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.ms-auto {
  margin-inline-start: auto;
}

.me-auto {
  margin-inline-end: auto;
}

.mt-auto {
  margin-top: auto;
}

.mr-auto {
  margin-right: auto;
}

.mb-auto {
  margin-bottom: auto;
}

.ml-auto {
  margin-left: auto;
}

.question-item {
  transition: opacity 0.3s ease;
}

.open-qitem {
  opacity: 1;
  transition: 1s all ease-in-out;
  transform: translateX(20);
}

.close-qitem {
  position: absolute;
  top: 0;
  opacity: 0;
  transform: translateX(100);
  transition: 1s all ease-in-out;
}

.img-stream {
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
}

.img-stream:hover {
  transform: scale(1.04);
}

.card-candidates {
  background-color: #fff;
  border-radius: 16px;
  border: 1px solid #e9ecef;
}

.card-price {
  background: #fff;
  padding: 16px;
  border-radius: 32px;
  border: 1px solid #92388e;
  cursor: pointer;
  // padding: 2rem;
}

.btn-card-price {
  background: #f1efff;
  border: 1px solid #fff;
  color: #92388e;
  font-weight: 700;
  margin-top: 12rem;
  border-radius: 8px;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 12;
  padding-right: 12;
}

.btn-card-price:hover {
  background: #92388e;
  border: 1px solid #fff;
  color: #fff;
  font-weight: 700;
  margin-top: 12rem;
  border-radius: 8px;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 12;
  padding-right: 12;
}

.btn-card-price-active {
  background: #92388e;
  border: 1px solid #fff;
  color: #fff;
  font-weight: 700;
  margin-top: 12rem;
  border-radius: 8px;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 12;
  padding-right: 12;
}

.card-price-active {
  // margin-bottom: 4rem;
  background: #fff;
  padding: 12px;
  border-radius: 32px;
  border: 8px solid #92388e;
  cursor: pointer;
}

.btn-subscribe {
  color: #fff;
  background-color: #92388e;
  border: solid 1px #92388e;
  padding: 8px 16px;
  border-radius: 80px;
  font-weight: bold;

  &:hover {
    background-color: rgba($color: #92388e, $alpha: 0.9);
  }
}

.px-8 {
  padding-left: 8px;
  padding-right: 8px;
}

// @keyframes scale-up {
//     from {
//       transform: scale(1);
//     }
//     to {
//       transform: scale(1.05);
//     }
//   }

@keyframes move-up {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-40px);
  }
}

@keyframes move-down {
  from {
    transform: translateY(-40px);
  }

  to {
    transform: translateY(0);
  }
}

// .card-price:hover {
//     margin-bottom: 4rem;
//     animation: move-up 0.5s ease forwards;
//     border: 4px solid #92388E;
// }

// .card-price {
//     transition: transform 0.5s ease;
// }

// .card-price:not(:hover) {
//     animation: move-down 0.5s ease forwards;
// }

.card-blur {
  filter: blur(5px);
}

.subscription-card::-webkit-scrollbar {
  display: none;
  /* Untuk browser yang mendukung - Chrome, Edge, dll */
}

.subscription-card {
  scrollbar-width: none;
  /* Untuk browser yang mendukung - Firefox */
}

.subscirption-card::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.subscirption-card {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.card-candidates:hover {
  .img-profile {
    -webkit-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
    scale: 1.15;
  }
}

.z-index-10 {
  z-index: 10;
}
.z-index-20 {
  z-index: 20;
}
.z-index-30 {
  z-index: 30;
}
